body {
  background-color: #4158D0;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  background-color: #FAF7F4;
  min-height: 100vh;
  padding-bottom: 2em;
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.resulting-text {
  text-align: left;
  padding: 1em;
  border: solid 1px #c7c7c7;
  font-family: 'Tinos', Georgia, serif;
  font-size: 1rem;
  line-height: 1.5;
}

.hero{
  padding: 3em 0;
}


.hero h1 {
  font-size: 6em;
  font-weight: normal;
}

.hero h3 {
  font-size: 1.8em;
  font-weight: normal;
}

.hero h5 {
  font-size: 1.2em;
  font-weight: normal;
}

.card{
  background-color: white;
  /*box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);*/
  border-radius: 0em;
  padding: 0;
  text-align: left;
}

.card-tab{
  padding: 2em;
}

.MuiTabs-root{
  border-bottom: solid #C6D0CC;
  padding: 2em 2em 0 2em;

}
.MuiButtonBase-root{
  font-size: 1rem !important;
}

.MuiButtonBase-root.Mui-selected {
  color:black !important;
  font-weight: bold;
}

.css-1aquho2-MuiTabs-indicator {
  height: 5px !important;
  background-color: #C6D0CC !important;
}

.input-textarea{
  width: calc(100% - 4em);
  border-radius: 0em;
  padding: 2em;
  border-color: #C6D0CC;
}



.vertical-divider{
  height: 3em;
}

.navbar{
  background-color: #E0E0D1;
  height: 62px;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.navbar img {
  max-height: 35px;
}

.nav-left{
  width: 75%;
  padding: 1em;
  text-align: left;
  display: flex;
  align-items: center;
}

.nav-right{
  width: 25%;
  padding: 1em;
  text-align: right;
}

.top-nav-link {
  padding: 6px 1rem 6px 1rem;
  color: black;
  font-weight: 700;
  text-decoration: none;
}

.top-nav-link.active {
  border-bottom: solid 5px #C6D0CC;
}

.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  overflow-y: scroll;
  z-index: 1000;
}

.modal-nav{
  position: absolute;
  top: 0;
  width: 100%;
  text-align: right;
  padding: 5px;
  height: 4 0px;
}

.reading-modal{
  margin-top:10px;
}

@media only screen and (max-width: 600px) {
  .hero h1 {
    font-size: 3em;
    font-weight: normal;
  }
  
  .hero h3 {
    font-size: 1em;
    font-weight: normal;
  }

  .hero{
    padding: 2em 0;
  }

  .card{
    border-radius: 1em;
    padding: 0.6em;
  }

  .MuiContainer-root{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .input-textarea{
    padding: 0.8em;
    width: calc(100% - 1.8em);
  }
}


.buycoffee-btn{
  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 5rem;
  padding: 10px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
}

.footer {
  padding:4em 2em;
  min-height: 10rem;
  background-color: #333;
  color: wheat;
}

.highlights-bar-item{
  text-align: center;
  padding: 1em 3em;
}
.highlights-bar{
  background-color: #333;
  color: white;
  width: 100%;
  text-align: center;
  align-items: center;
  vertical-align:middle;
  padding: 2em;
}

.highlights-bar-list{

  padding: 2em;
  display: flex;
}


.main-content{
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.main-content-container{
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.content-div-img{
  max-width: 100%;
}

.content-row{
  margin-top: 2em;
  padding: 2em;
}